import { ResourcesWrapper } from './service-resource-select.style'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { Button, Dropdown, Switch, Typography, theme } from 'antd'
import { Avatar } from 'components/avatar/avatar'
import { UpOutlined, PlusOutlined, CloseOutlined, MoreOutlined, CheckOutlined } from '@ant-design/icons'
import { useTouchDetector, useTranslate, useWindowDimensions } from 'utils'
import { useConfig, useResources } from 'api'
import { HorizontalContainer, Loading, Message, NotFound, PageError, VerticalContainer } from 'components/styled'
import { Resource, ResourceGroup, SimpleResource } from 'types'
const { Text } = Typography

type Props = {
    SLRs: ResourceGroup[]
    index: number
    onChange: (serLocResources: ResourceGroup) => void
}

export const ServiceResourceSelect = ({ SLRs, index, onChange }: Props) => {

    const serLocResources = SLRs[index]

    //hooks:
    const { __ } = useTranslate()
    const { token: {
        paddingXXS,
        paddingSM,
        padding,
        paddingMD,
        paddingLG,
        marginXXS,
        marginXS,
        marginSM,
        margin,
        marginMD,
        borderRadiusLG,
        boxShadow,
        fontSize,
        fontSizeSM,
        colorBgContainer,
        colorBorder,
        colorBorderSecondary,
    }} = theme.useToken()
    const { SM, LG } = useWindowDimensions()

    //api:
    const { data: config } = useConfig()
    const { data: allResources, isLoading } = useResources(null, serLocResources.type.id, null, 100)

    //state:
    const [ isAddResourcesOpen, setIsAddResourcesOpen ] = useState(false)

    //selectedResources:
    const selectedResources = useMemo(() => {
        if(allResources){
            return allResources.filter(resource => serLocResources.resources.some(slr => slr.id === resource.id))
        }else{
            return []
        }
    }, [allResources, serLocResources.resources])

    const getOtherRowsSelectedResources = (SLRs: ResourceGroup[], index: number) => {
        const resources: SimpleResource[] = []
        const type = SLRs[index].type
        SLRs.map((slr, i) => {
            if(slr.type.id === type.id && i !== index ){
                slr.resources.map(resource => {
                    if(!resources.find(r => r.id === resource.id)){
                        resources.push(resource)
                    }
                })
            }
        })
        return resources
    }

    //availableResources:
    const availableResources = useMemo(() => (
        allResources ? allResources.filter(r => (
            !serLocResources.resources.concat(getOtherRowsSelectedResources(SLRs, index)).some(slr => slr.id === r.id)
        )) : []
    ), [allResources, SLRs])
    
    //addResource:
    const addResource = (resource: Resource) => {
        const tmpResources = [...serLocResources.resources]
        tmpResources.push(resource)
        onChange({
            ...serLocResources,
            resources: [...tmpResources]
        })
    }

    //removeResource:
    const removeResource = (rId: string) => {
        const tmpResources = [...serLocResources.resources]
        onChange({
            ...serLocResources,
            resources: tmpResources.filter(r => r.id !== rId)
        })
    }

    const areAnyOptionsLeft = () => (
        availableResources.length > 0 ||
        (
            serLocResources.isSelectable &&
            serLocResources.hideAnyAvailable
        )
    )

    const isAddButtonVisible = () => !isAddResourcesOpen && areAnyOptionsLeft()
    const isAnyAvailableAvailable = serLocResources.isSelectable && serLocResources.hideAnyAvailable
    const isAnyAvailableSelected = serLocResources.isSelectable && !serLocResources.hideAnyAvailable
    const isReachedMaxResources = selectedResources.length >= ((config && config.plan?.maxResources) ? config?.plan?.maxResources : 0)

    //change options list state:
    useEffect(() => {
        setIsAddResourcesOpen(areAnyOptionsLeft())
    }, [serLocResources.resources, serLocResources.hideAnyAvailable])

    return (!config ? null : (

        <VerticalContainer style={{
            padding: SM ? `${padding}px ${paddingLG}px ${paddingMD}px ${paddingLG}px` : padding,
            margin: SM ? undefined : `0 ${margin}px`,
            border: `solid 1px ${colorBorder}`,
            borderRadius: borderRadiusLG*2,
            gap: marginMD
        }}>

            <HorizontalContainer>

                <Text style={{ margin: 0 }}>

                    {index ? (
                        <>{__`and_one_of_these_resources`}</>
                    ) : (
                        <>{__`one_of_these_resources`}</>
                    )}
                    
                </Text>
                
                {LG ? (
                    <HorizontalContainer style={{ gap: marginSM }}>
                        <ResourceTypeOptions serLocResources={serLocResources} renderBorder={true} onChange={onChange}/>
                    </HorizontalContainer>
                ) : (
                    <Dropdown
                        dropdownRender={() => (
                            <VerticalContainer
                                style={{
                                    paddingTop: paddingXXS,
                                    paddingBottom: paddingXXS,
                                    backgroundColor: colorBgContainer,
                                    borderRadius: borderRadiusLG,
                                    boxShadow: boxShadow,
                                    gap: 0
                                }}
                                onClick={e => e.stopPropagation()}
                            >
                                <ResourceTypeOptions serLocResources={serLocResources} renderBorder={false} onChange={onChange}/>
                            </VerticalContainer>
                        )}
                        
                        destroyPopupOnHide
                        trigger={['click']}
                    >
                        <Button
                            type={'text'}
                            shape={'circle'}
                            size={'large'}
                            icon={<MoreOutlined/>}
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                            }}
                        />
                    </Dropdown>
                )}

            </HorizontalContainer>
            
            {(isLoading ? <Loading style={{ paddingBottom: paddingMD }}/> : !allResources ? <PageError/> : (

                <VerticalContainer style={{ gap: margin }}>

                    {/* selected resources list */}
                    <ResourcesWrapper isEmpty={selectedResources.length === 0}>

                        {selectedResources.length > 0 ? (
                            
                            <>
                                
                                {/* any available button */}
                                {isAnyAvailableSelected ? (
                                    <SelectedResourceItem
                                        key={1}
                                        onRemove={() => {
                                            onChange({
                                                ...serLocResources,
                                                hideAnyAvailable: true
                                            })
                                        }}
                                    >
                                        <AnyAvailableAvatar/>
                                    </SelectedResourceItem>
                                ) : <></>}
                                
                                {/* selected resources list */}
                                {selectedResources.map(resource => (
                                    <SelectedResourceItem
                                        key={resource.id}
                                        resource={resource}
                                        onRemove={() => removeResource(resource.id)}
                                    />
                                ))}

                                {/* add button */}
                                {isAddButtonVisible() ? (
                                    <Button
                                        title={isReachedMaxResources ? __`you_reached_your_plans_max_limit` : __('add_resource', { resource: serLocResources.type.name })}
                                        shape='circle'
                                        type='default'
                                        disabled={isReachedMaxResources}
                                        style={{ width: 48, height: 48, margin: `${marginXXS}px ${marginSM}px` }}
                                        onClick={() => setIsAddResourcesOpen(true)}
                                    >
                                        <PlusOutlined/>
                                    </Button>
                                ) : <></>}

                            </>
                            
                            ) : (

                                <NotFound
                                    message={__(
                                        availableResources.length ? 'no_resource_selected' : 'no_resource_found',
                                        { resource: serLocResources.type.name }
                                    )}
                                    style={{ fontSize, margin }}
                                />

                            )
                        }

                    </ResourcesWrapper>

                    {/* add resources */}
                    {isAddResourcesOpen && (

                        <VerticalContainer style={{ paddingTop: paddingSM, borderTop: `solid 1px ${colorBorderSecondary}`, gap: marginXS }}>

                            <HorizontalContainer>

                                <Text style={{ fontFamily: 'inter-medium' }}>
                                    {__('add_resource', { resource: serLocResources.type.name })}
                                </Text>
                                {serLocResources.resources.length ? (
                                    <Button
                                        type='link'
                                        onClick={() => setIsAddResourcesOpen(false)}
                                    >
                                        <Text>{__`close`}</Text>
                                        <UpOutlined style={{ fontSize: fontSizeSM }}/>
                                    </Button>
                                ) : <></>}

                            </HorizontalContainer>

                            <ResourcesWrapper isEmpty={!areAnyOptionsLeft()}>
                                
                                {/* any available button */}
                                {isAnyAvailableAvailable ? (
                                    <div key={1}>
                                        <AnyAvailableAvatar
                                            onClick={() => {
                                                onChange({
                                                    ...serLocResources,
                                                    hideAnyAvailable: false
                                                })
                                            }}
                                        />
                                    </div>
                                ) : <></>}
                                
                                {availableResources.map(resource => (
                                    <div key={resource.id}>
                                        <Avatar
                                            name={resource.name}
                                            image={resource.image.path}
                                            isVertical={true}
                                            style={{
                                                width: '80px',
                                                height: '100%',
                                                paddingLeft: paddingXXS,
                                                paddingRight: paddingXXS
                                            }}
                                            onClick={() => addResource(resource)}
                                        />
                                    </div>
                                ))}

                            </ResourcesWrapper>

                        </VerticalContainer>

                    )}

                </VerticalContainer>

            ))}

        </VerticalContainer>
    ))

}

type SelectedResourceItemProps = {
    resource?: Resource
    onRemove: () => void
}

const SelectedResourceItem = ({ resource, children, onRemove }: PropsWithChildren<SelectedResourceItemProps>) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: {
        paddingXXS,
        colorTextSecondary,
        colorBgContainer,
        colorBgContainerDisabled,
        fontSizeSM
    }} = theme.useToken()
    const { isTouchscreen } = useTouchDetector()

    //state:
    const [ isRemoveVisible, setIsRemoveVisible ] = useState(false)

    return (
        <div
            key={resource?.id ?? 1}
            style={{ position: 'relative' }}
            onMouseOver={() => setIsRemoveVisible(true)}
            onMouseOut={() => setIsRemoveVisible(false)}
        >

            {/* resource */}
            {resource ? (
                <Avatar
                    name={resource.name}
                    image={resource.image.path}
                    isVertical={true}
                    style={{
                        width: '80px',
                        height: '100%',
                        paddingLeft: paddingXXS,
                        paddingRight: paddingXXS,
                        marginRight: -paddingXXS
                    }}
                />
            ) : children}

            {/* close button */}
            {(isRemoveVisible || isTouchscreen) && (
                <Button
                    type='link'
                    shape='circle'
                    size='small'
                    title={__`remove`}
                    style={{
                        width: 23,
                        height: 23,
                        backgroundColor: colorBgContainerDisabled,
                        border: `solid 1px ${colorBgContainer}`,
                        position: 'absolute',
                        top: -8,
                        right: 0
                    }}
                    onClick={onRemove}
                >
                    <CloseOutlined style={{ fontSize: fontSizeSM, color: colorTextSecondary }}/>
                </Button>
            )}

        </div>
    )
}

type ResourceTypeOptionsProps = {
    serLocResources: ResourceGroup
    renderBorder: boolean
    onChange: (serLocResources: ResourceGroup) => void
}

const ResourceTypeOptions = ({ serLocResources, renderBorder, onChange }: ResourceTypeOptionsProps) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { paddingXS, padding, paddingMD, colorBorder }} = theme.useToken()

    return (
        <>
            {/* isSelectable */}
            <HorizontalContainer
                style={{
                    padding: `${paddingXS}px ${padding}px`,
                    border: renderBorder ? `solid 1px ${colorBorder}` : undefined,
                    borderRadius: paddingMD
                }}
            >
                <HorizontalContainer style={{ gap: 0 }}>
                    <Text>{__`selectable_in_experience`}</Text>
                    <Message message={__`by_selecting_this_option_user_will_be_able_to_choose_between_these_resources_in_the_booking_experience`}/>
                </HorizontalContainer>
                <Switch
                    size='small'
                    checked={serLocResources.isSelectable}
                    onChange={isChecked => {
                        onChange({
                            ...serLocResources,
                            isSelectable: isChecked
                        })
                    }}
                />
            </HorizontalContainer>

            {/* blocksDuringAppointment */}
            <HorizontalContainer
                style={{
                    padding: `${paddingXS}px ${padding}px`,
                    border: renderBorder ? `solid 1px ${colorBorder}` : undefined,
                    borderRadius: paddingMD
                }}
            >
                <HorizontalContainer style={{ gap: 0 }}>
                    <Text>{__`blocks_during_booking`}</Text>
                    <Message message={__`by_selecting_this_option_the_booked_resource_cannot_be_booked_during_the_appointment`}/>
                </HorizontalContainer>
                <Switch
                    size='small'
                    checked={serLocResources.blocksDuringAppointment}
                    onChange={isChecked => {
                        onChange({
                            ...serLocResources,
                            blocksDuringAppointment: isChecked
                        })
                    }}
                />
            </HorizontalContainer>
        </>
    )

}

type AvaProps = {
    onClick?: (e: any) => void
}
const AnyAvailableAvatar = ({ onClick }: AvaProps) => {
    const { __ } = useTranslate()
    const { token: { fontSize, paddingXXS, colorText, colorTextDisabled }} = theme.useToken()
    return (
        <Avatar
            name={__`any_available`}
            image={
                <div style={{
                    width: 47,
                    height: 47,
                    borderRadius: '50%',
                    border: `solid 1px ${colorTextDisabled}`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <CheckOutlined style={{ color: colorText, fontSize }}/>
                </div>
            }
            isVertical={true}
            style={{
                height: '100%',
                paddingLeft: paddingXXS,
                paddingRight: paddingXXS
            }}
            onClick={onClick}
        />
    )
}
