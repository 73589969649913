import { Config } from 'types'
import { getShopId, queryClient, useApiQuery, useAuthentication } from 'utils'

export const useConfig = () => {

    //hooks:
    const { isLoggedIn, logout } = useAuthentication()
    const shopId = getShopId()

    //values:
    const getBasicConfig = !isLoggedIn || !shopId
    const cacheKey = ['config', getBasicConfig ? null : shopId]

    return useApiQuery<Config>({
        query: getBasicConfig ? `
            query getConfig{
                getConfig{
                    locale
                }
            }
        ` : `
            query getConfig(
                $id: String
            ){
                getConfig(
                    id: $id
                ){

                    locale
                    features
                    maxApp
                    canSetPermissions

                    plan{
                        id
                        planId
                        title
                        name
                        description
                        price
                        maxServices
                        maxResources
                        maxLocations
                        maxGroupAppointmentsSlots
                        maxFlows
                        listed
                    }

                }
            }
        `,
        variables: getBasicConfig ? undefined : {
            id: shopId
        },
        cacheKey,
        noAuth: getBasicConfig,
        onSuccess: json => {
            const config: Config = json.data.getConfig
            return config
        },
        onError: () => {
            queryClient.removeQueries(cacheKey)
            if(isLoggedIn){
                logout()
            }
        }
    })
}
