import { useEffect, useState } from 'react'
import { useConfig, useCreateService as useCreateService, useCurrentShop, useService as useService, useUpdateService as useUpdateService } from 'api'
import { FlexStartInterval as FlexStartInterval, Service as Service, ServiceFormSchema as ServiceFormSchema, Shop } from 'types'
import { CloudUploadOutlined } from '@ant-design/icons'
import { Loading, PageContentWrapper, PageError, VerticalContainer } from 'components/styled'
import { getShopId, queryClient, useTranslate, useWindowDimensions, validateFormValues } from 'utils'
import { useNavigate, useParams } from 'react-router-dom'
import { message, theme } from 'antd'
import { Form } from 'react-final-form'
import { PageHeader } from 'components/page-header/page-header'
import { ServiceDuration } from 'components/service/service-duration/service-duration'
import { ServiceAppointment } from 'components/service/service-appointment/service-appointment'
import { ServiceOtherOptions } from 'components/service/service-other-options/service-other-options'
import { ServiceGroupAppointment } from 'components/service/service-group-appointment/service-group-appointment'
import { ServiceRemove } from 'components/service/service-remove/service-remove'
import { ServiceBufferTime } from 'components/service/service-buffer-time/service-buffer-time'
import { ServiceStorefront } from 'components/service/service-storefront/service-storefront'
import { ServiceLocations } from 'components/service/service-locations/service-locations'

export const ServiceForm = () => {
    const params = useParams()
    return params.id ? <EditService id={params.id}/> : <ServiceFormController service={null}/>
}

type EditServiceProps = {
    id: string
}

const EditService = ({ id }: EditServiceProps) => {

    const navigate = useNavigate()
    const { data: service, isLoading } = useService(id, undefined, () => navigate('/service'))

    //redirect if not found:
    useEffect(() => {
        if(service === null || service === false){
            navigate('/service')
        }
    }, [isLoading])

    return isLoading ? <Loading/> : !service ? <PageError/> : <ServiceFormController service={service}/>

}

type ServiceControllerProps = {
    service: Service | null
}

const ServiceFormController = ({ service }: ServiceControllerProps) => {

    const isCreate = !service

    //hooks:
    const { token: { padding, margin, marginLG } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const { data: config } = useConfig()
    const { data: shop } = useCurrentShop()
    const navigate = useNavigate()

    //state:
    const [ initialValues, setInitialValues ] = useState(service ?? getDefaultValues(shop || null))
    const [ savingValues, setSavingValues ] = useState(service ?? getDefaultValues(shop || null))

    //mutation:
    const { mutate: createService, isLoading: isCreateLoading, data: createResponse } = useCreateService(savingValues)
    const { mutate: updateService, isLoading: isUpdateLoading, data: updateResponse } = useUpdateService(savingValues)

    const create = (values: Service) => {
        setSavingValues(values)
        createService()
    }
    const update = (values: Service) => {
        setSavingValues(values)
        updateService()
    }

    //after create:
    useEffect(() => {
        if(createResponse){
            queryClient.invalidateQueries(['services', getShopId()])
            navigate(`/service`)
        }
    }, [isCreateLoading])

    //after update:
    useEffect(() => {
        if(updateResponse){
            setInitialValues({...savingValues})
            queryClient.invalidateQueries(['service', savingValues.id])
            queryClient.invalidateQueries(['services', getShopId()])
            message.success(__`service_updated`)
        }
    }, [isUpdateLoading])

    return !config ? <PageError/> : (
        
        <PageContentWrapper>

            <Form
                initialValues={initialValues}
                subscription={{ dirty: true }}
                validate={validateFormValues(ServiceFormSchema(__))}
                onSubmit={values => ServiceFormSchema(__).validate(values).then(
                    data => isCreate ? create(data as Service) : update(data as Service)
                )}
                render={({ form, dirty, handleSubmit,  }) => (

                    <VerticalContainer style={{ gap: margin }}>

                        {/* header */}
                        <PageHeader
                            title={isCreate ? __`create_service` : initialValues.title}
                            description={isCreate ? __`create_new_service_and_add_it_to_your_locations` : __`change_your_service_settings_and_save_it`}
                            primaryButton={{
                                name: isCreate ? __`create` : __`save`,
                                icon: <CloudUploadOutlined/>,
                                mode: (isCreateLoading || isUpdateLoading) ? 'loading' : dirty ? 'normal': 'disabled',
                                onClick: handleSubmit
                            }}
                            secondaryButton={dirty ? {
                                name: __`discard`,
                                mode: (isCreateLoading || isUpdateLoading) ? 'disabled' : 'normal',
                                onClick: () => form.reset()
                            } : undefined}
                            backButton={{
                                name: __`back`,
                                onClick: () => navigate('/service')
                            }}
                            isFormDirty={dirty}
                        />

                        <VerticalContainer style={{ padding: SM ? `0 ${padding}px` : 0, gap: marginLG }}>

                            {/* locations */}
                            {<ServiceLocations/>}

                            {/* duration */}
                            <ServiceDuration/>

                            {/* appointment details */}
                            <ServiceAppointment/>

                            {/* buffer time */}
                            <ServiceBufferTime/>

                            {/* group appointments */}
                            {<ServiceGroupAppointment/>}

                            {/* storefront */}
                            <ServiceStorefront/>

                            {/* other options */}
                            <ServiceOtherOptions/>

                            {/* remove */}
                            {!isCreate && <ServiceRemove/>}

                        </VerticalContainer>
                    
                    </VerticalContainer>
                    
                )}
            />
        </PageContentWrapper>
    )
    
}

const getDefaultValues = (shop: Shop | null): Service => ({
    
    id: '',
    title: '',
    image: {
        id: null,
        path: null
    },

    variants: [],

    location: {
        content: null
    },
    eventDescription: null,

    bufferTimeBefore: 0,
    bufferTimeAfter: 0,
    bufferFromNow: 0,

    groupAppointments: false,
    groupAppointmentSlots: null,

    instantBooking: false,
    createOrder: false,

    disableSameDayBooking: false,
    flexStartInterval: (shop && shop.isMigrated) ? FlexStartInterval.FIFTEEN : undefined,
    roundStartInterval: 0,
    bookingUntilDays: null,

    customerCanCancel: false,
    customerCanReschedule: false,
    customerCanManageBefore: null,
    refundMoneyOnCancel: false,

    locations: []

})
