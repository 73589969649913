import { ResourceType, SimpleLocation, SimpleResource, Variant } from 'types'
import { boolean, number, object, string } from 'yup'
import { TranslateFunction } from 'utils'

export type Service = {

    id: string
    title: string
    image: {
        id: string | null
        path: string | null
    }

    variants: Variant[]

    location: { content: string | null }
    eventDescription: string | null

    bufferTimeBefore: number
    bufferTimeAfter: number
    bufferFromNow: number

    groupAppointments: boolean
    groupAppointmentSlots: number | null

    instantBooking: boolean | null
    createOrder: boolean

    bookingUntilDays: number | null
    disableSameDayBooking: boolean
    flexStartInterval?: FlexStartInterval | null
    roundStartInterval: 0 | 15 | 30

    customerCanCancel: boolean
    customerCanReschedule: boolean
    customerCanManageBefore: number | null
    refundMoneyOnCancel: boolean

    locations: ServiceLocation[]

}

export type ServiceLocation = SimpleLocation & {
    resources: ResourceGroup[]
}

export type ResourceGroup = {
    type: ResourceType
    blocksDuringAppointment: boolean
    isSelectable: boolean
    hideAnyAvailable: boolean
    resources: SimpleResource[]
}

export enum FlexStartInterval {
    FIFTEEN = 'FIFTEEN',
    THIRTY = 'THIRTY',
    FORTY_FIVE = 'FORTY_FIVE',
    SIXTY = 'SIXTY'
}

export type CalendarSlotService = Pick<Service, 'id' | 'title' | 'groupAppointments' | 'groupAppointmentSlots'>
export type AppointmentService = Pick<Service, 'id' | 'title'>

export const ServiceFormSchema = (__: TranslateFunction) => object({
    
    eventDescription:            string().nullable(),

    bufferTimeBefore:            number().typeError(__`must_specify_a_number`).integer(__`must_be_an_integer`).min(0),
    bufferTimeAfter:             number().typeError(__`must_specify_a_number`).integer(__`must_be_an_integer`).min(0),
    bufferFromNow:               number().typeError(__`must_specify_a_number`).integer(__`must_be_an_integer`).min(0),

    groupAppointments:           boolean(),
    groupAppointmentSlots:       number().when('groupAppointments', {
        is: true,
        then: schema => schema.required(__`must_specify_a_number`).integer(__`must_be_an_integer`).min(1),
        otherwise: schema => schema.nullable()
    }),

    bookingUntilDays:            number().nullable().integer(__`must_be_an_integer`).min(0),
    disableSameDayBooking:       boolean(),
    roundStartInterval:          number().oneOf([0, 15, 30]),

    customerCanCancel:           boolean(),
    customerCanReschedule:       boolean(),
    customerCanManageBefore:     number().nullable().integer(__`must_be_an_integer`).min(0),
    refundMoneyOnCancel:         boolean()

})
